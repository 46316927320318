






















import Vue from 'vue';
import ProviderUtils, { ProviderCategory } from '@/utils/ProviderUtils';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<ProviderCategory>[] {
      return [
        {
          text: this.$i18n.tc('provider.category.key'),
          value: 'key',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: ProviderCategory[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await ProviderUtils.api.category.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: ProviderCategory) {
      await this.$router.push({
        name: 'provider-category-edit',
        params: { key: item.key.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'provider-category-create',
      });
    },
  },
});
